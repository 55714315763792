import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >

         
<path d="M3700 5306 l0 -356 65 0 65 0 0 105 0 105 93 0 92 -1 74 -104 73
-105 69 0 70 0 -76 109 c-41 60 -75 113 -74 117 0 5 19 23 42 39 150 110 116
356 -59 420 -39 14 -90 19 -241 23 l-193 4 0 -356z m385 224 c67 -34 93 -119
57 -190 -28 -57 -67 -72 -198 -78 l-114 -5 0 148 0 147 113 -4 c71 -2 123 -9
142 -18z"/>
<path d="M4490 5305 l0 -355 65 0 65 0 0 150 0 150 185 0 185 0 0 -150 0 -151
60 3 61 3 0 350 1 350 -61 3 -60 3 -3 -148 -3 -148 -182 -3 -183 -2 0 150 0
150 -65 0 -65 0 0 -355z"/>
<path d="M5360 5305 l0 -355 65 0 65 0 0 355 0 355 -65 0 -65 0 0 -355z"/>
<path d="M5730 5305 l0 -355 65 0 65 0 0 93 0 93 53 52 c29 29 56 52 59 52 4
0 61 -65 128 -145 l122 -145 75 0 c59 0 73 3 66 13 -5 6 -76 95 -157 195
l-147 184 103 110 c57 60 123 131 146 159 l44 49 -74 -1 -73 -1 -170 -179
-170 -180 -3 181 -2 180 -65 0 -65 0 0 -355z"/>
<path d="M6840 5306 l0 -356 224 0 c123 0 248 4 277 10 124 23 188 77 197 169
7 81 -40 160 -112 184 -6 2 9 22 31 44 93 93 55 229 -77 279 -37 15 -89 18
-292 22 l-248 4 0 -356z m410 169 c26 -23 25 -47 -1 -70 -16 -15 -37 -19 -97
-20 l-77 0 -3 44 c-5 69 -2 72 82 68 56 -3 81 -8 96 -22z m28 -261 c17 -12 22
-24 20 -47 -3 -27 -9 -34 -43 -45 -23 -8 -70 -12 -110 -10 l-70 3 -3 44 c-5
70 -3 71 95 71 65 0 95 -4 111 -16z"/>
<path d="M7670 5437 c0 -249 10 -312 59 -379 64 -87 148 -123 286 -122 133 2
222 41 278 122 49 69 57 124 57 375 l0 227 -114 0 -115 0 -3 -232 c-3 -260 -7
-276 -73 -298 -42 -14 -77 -5 -107 27 -25 27 -25 28 -31 348 l-2 150 -117 3
-118 3 0 -224z"/>
<path d="M8430 5654 c0 -3 59 -103 130 -223 l130 -218 0 -132 0 -131 120 0
120 0 0 131 c0 130 0 131 33 187 190 321 227 384 227 388 0 2 -51 4 -113 4
l-112 0 -70 -120 c-39 -66 -72 -120 -75 -120 -3 0 -37 54 -75 120 l-69 120
-123 0 c-68 0 -123 -3 -123 -6z"/>
<path d="M1411 5334 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1440 5314 c0 -8 -4 -12 -10 -9 -5 3 -10 -1 -10 -9 0 -9 4 -16 9 -16
5 0 11 -9 14 -21 5 -17 3 -18 -11 -7 -14 11 -15 10 -8 -12 4 -14 10 -51 13
-82 3 -32 10 -58 15 -58 5 0 7 -4 4 -8 -6 -10 22 -86 62 -168 135 -278 389
-486 690 -563 61 -16 112 -26 113 -23 5 48 -5 351 -12 364 -6 12 -6 18 0 18 6
0 8 7 5 15 -4 9 -19 15 -40 15 -30 0 -34 3 -34 25 0 23 -4 25 -40 25 -22 0
-40 5 -40 10 0 6 18 10 40 10 22 0 40 5 40 10 0 6 -18 10 -40 10 -38 0 -40 2
-40 30 l0 31 -67 -3 c-68 -3 -68 -3 -71 25 -3 23 -8 27 -35 28 -30 2 -31 2 -4
6 38 6 35 31 -5 35 -25 2 -34 8 -36 26 -2 14 -10 22 -23 22 -17 0 -19 -5 -14
-42 4 -32 3 -38 -4 -23 -5 11 -10 30 -10 42 -1 18 -8 22 -43 25 -36 2 -44 7
-46 26 -2 15 -10 22 -26 22 -13 0 -29 7 -36 15 -10 12 -9 15 6 15 16 0 16 1 2
13 -9 6 -18 21 -20 32 -4 23 -39 28 -100 15 -21 -4 -38 -5 -39 -1 0 3 -3 15
-6 26 -3 11 -5 32 -4 48 0 23 -4 27 -29 27 -25 0 -30 4 -30 25 0 23 -4 25 -40
25 -30 0 -40 -4 -40 -16z m115 -63 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25
5 0 11 -4 15 -9z m582 -408 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0
-30z"/>
<path d="M6524 5190 c-11 -4 -33 -23 -48 -41 -25 -29 -28 -39 -24 -86 3 -38
11 -60 28 -78 81 -86 230 -33 230 81 0 25 -7 58 -15 73 -25 49 -112 75 -171
51z"/>
<path d="M1770 5120 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2925 4438 l-20 -21 23 12 c12 6 22 16 22 21 0 13 -2 12 -25 -12z"/>
<path d="M2820 4434 c0 -14 3 -14 15 -4 8 7 15 14 15 16 0 2 -7 4 -15 4 -8 0
-15 -7 -15 -16z"/>
<path d="M2865 4430 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2796 4393 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M2835 4400 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>

       
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}